<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>تقرير الزيارة</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  label="مصدر المعلومات"
                  placeholder="مصدر المعلومات"
                  v-model="InfoSource"
                  :rules="InfoSourceRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-file-input
                  v-model="photos"
                  multiple
                  label="الصور"
                ></v-file-input>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="عدد الهدايا"
                  placeholder="عدد الهدايا"
                  v-model="numOfGifts"
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="قيمة الهدية"
                  placeholder="قيمة الهدية"
                  v-model="costOfGifts"
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="الملاحظات"
                  placeholder="الملاحظات"
                  v-model="notes"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <v-card max-width="500" outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1"
                        >المنتجات</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-form ref="form2" v-model="valid2" lazy-validation>
                    <v-list-item>
                      <v-flex xs12>
                        <v-autocomplete
                          :items="products"
                          v-model="producId"
                          :filter="customFilter3"
                          color="white"
                          item-text="item"
                          item-value="value"
                          :rules="productRules"
                          label="المنتج"
                          return-object
                          required
                        ></v-autocomplete>
                      </v-flex>
                    </v-list-item>
                    <v-list-item>
                      <v-flex xs12>
                        <v-text-field
                          required
                          label="السعر"
                          placeholder="السعر"
                          v-model="product.price"
                          :rules="priceRules"
                          type="number"
                        ></v-text-field>
                      </v-flex>
                    </v-list-item>
                    <v-list-item>
                      <v-flex xs12>
                        <v-text-field
                          required
                          type="number"
                          placeholder="الاحتياج اليومي"
                          label="الاحتياج اليومي"
                          :rules="DneedsRules"
                          v-model="product.Dneeds"
                        ></v-text-field>
                      </v-flex>
                    </v-list-item>
                    <v-list-item>
                      <v-flex xs12>
                        <v-text-field
                          required
                          placeholder="الاحتياج الشهري"
                          label="الاحتياج الشهري"
                          v-model="product.Mneeds"
                          type="number"
                          :rules="MneedsRules"
                        ></v-text-field>
                      </v-flex>
                    </v-list-item>
                    <v-list-item>
                      <v-flex xs12>
                        <v-autocomplete
                          :items="companies"
                          v-model="company"
                          :filter="customFilter"
                          color="white"
                          item-text="item"
                          item-value="value"
                          :rules="companiesRules"
                          label="الشركة"
                          return-object
                          required
                        ></v-autocomplete>
                      </v-flex>
                    </v-list-item>
                    <v-list-item>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="TransporterId"
                          :filter="customFilter2"
                          :items="transporters"
                          color="white"
                          item-text="item"
                          item-value="value"
                          :rules="transportersRules"
                          label="الناقل"
                          return-object
                          required
                        ></v-autocomplete>
                      </v-flex>
                    </v-list-item>
                  </v-form>
                  <v-card-actions>
                    <v-btn
                      style="
                        background: #58dd5b;
                        color: white;
                        margin-left: 5px;
                      "
                      @click="addProduct"
                      dark
                      ><b>أضف منتج</b></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex xs12 md6 v-if="products.length > 0">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center"><b>اسم المنتج</b></th>
                        <th class="text-center"><b>السعر</b></th>
                        <th class="text-center"><b>الاحتياج اليومي</b></th>
                        <th class="text-center"><b>الاحتياج الشهري</b></th>
                        <th class="text-center"><b>الشركة</b></th>
                        <th class="text-center"><b>الناقل</b></th>
                        <th class="text-center"><b>خيارات</b></th>
                      </tr>
                    </thead>
                    <tbody v-if="addProducts.length > 0">
                      <tr v-for="(item, index) in addProducts" :key="index">
                        <td class="text-center">{{ item.name }}</td>
                        <td class="text-center">{{ item.price }}</td>
                        <td class="text-center">{{ item.Dneeds }}</td>
                        <td class="text-center">{{ item.Mneeds }}</td>
                        <td class="text-center">{{ item.company }}</td>
                        <td class="text-center">{{ item.transport }}</td>
                        <td class="text-center">
                          <div style="display: flex" align="center">
                            <v-btn
                              small
                              @click.stop="removeProduct(index)"
                              style="
                                background: red;
                                color: white;
                                margin-left: 5px;
                              "
                              dark
                            >
                              <b>حذف</b>
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="7"
                          class="text-center"
                          style="color: gray; font-size: 12px"
                        >
                          لا توجد بيانات لعرضها
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="sendVisitRequest"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background: gray" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      photos: [],
      externalsTypeRules: [
        v => !!v || "نوع الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      externalsType: "",
      externalsTypes: [
        { item: "اختر جهة خارجية", value: "0" },
        { item: " مشاريع", value: 5 },
        { item: "مقاولين", value: 3 },
        { item: "مباسط", value: 4 },
        { item: "مصانع", value: 1 }
      ],
      productRules: [
        v => !!v || "المنتج مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      priceRules: [v => !!v || "السعر مطلوب"],
      DneedsRules: [v => !!v || "الاحتياج اليومي مطلوب"],
      MneedsRules: [v => !!v || "الاحتياج الشهري مطلوبة"],
      companiesRules: [
        v => !!v || "الشركة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      producId: "",
      TransporterId: "",
      transportersRules: [
        v => !!v || "الناقل مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      companyId: "",
      external: "",
      valid: true,
      valid2: true,
      transporters: [{ item: "اختر ناقل", value: "0" }],
      InfoSourceRules: [
        v => !!v || "اسم مصدر المعلومات مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      externals: [{ value: "0", text: "اختر الجهة الخارجية" }],
      products: [{ item: "اختر منتج", value: "0" }],
      companies: [{ item: "اختر شركة", value: "0" }],
      addProducts: [],
      externalsRules: [
        v => !!v || "الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      numOfGifts: "",
      costOfGifts: "",
      InfoSource: "",
      notes: "",
      // visit: {
      //   InfoSource: "",
      //   ExternalId: ""
      // },
      product: {
        price: "",
        Dneeds: "",
        Mneeds: "",
        company: "",
        transport: ""
      },
      company: "",
      visitReportProducts: []
    };
  },
  methods: {
    uploadPhotos() {
      var formData = new FormData();
      for (let i = 0; i < this.photos.length; i++) {
        let file = this.photos[i];
        formData.append("photos", file);
      }
      formData.append("VisitId", this.$route.params.visitId);
      this.ApiService.post("photo/addphoto", formData)
        .then(() => {})
        .catch(() => {});
    },
    addProduct() {
      if (this.$refs.form2.validate()) {
        this.addProducts.push({
          name: this.producId.item,
          price: this.product.price,
          Dneeds: this.product.Dneeds,
          Mneeds: this.product.Mneeds,
          company: this.company.item,
          transport: this.TransporterId.item
        });
        this.visitReportProducts.push({
          ProdId: this.producId.value,
          Price: this.product.price,
          Dneed: this.product.Dneeds,
          Mneed: this.product.Mneeds,
          CompId: this.company.value,
          TransporterId: this.TransporterId.value
        });
        this.producId = "";
        this.product = {
          price: "",
          Dneeds: "",
          Mneeds: ""
        };
        this.company = "";
        this.TransporterId = "";
      }
    },
    async getTransporters() {
      (this.transporters = [{ item: "اختر ناقل", value: "0" }]),
        this.ApiService.get("Transporters/getalltransporters")
          .then(res => {
            var allTransporters = res.data.responseData;
            allTransporters.forEach(el => {
              this.transporters.push({
                value: el.idtransporter,
                item: el.name
              });
            });
          })
          .catch(() => {});
    },
    removeProduct(index) {
      this.addProducts.splice(index, 1);
      this.visitReportProducts.splice(index, 1);
    },

    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter2(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter3(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    sendVisitRequest() {
      if (this.$refs.form.validate()) {
        let data = null;

        if (this.externalsType.value == 5) {
          data = {
            visit: {
              ExternalId: this.visit.externalId,
              Notes: this.visit.notes,
              InfoSource: this.visit.InfoSource
            },
            projectVisit: {
              Status: "Active",
              Notes: this.visit.notes,
              InfoSrc: this.visit.InfoSource
            },
            productNeedVisit: this.visitReportProducts
          };
        } else {
          data = {
            visit: {
              numOfGifts: this.numOfGifts,
              costOfGifts: this.costOfGifts,
              ExternalId: this.externalId,
              Notes: this.notes,
              InfoSource: this.InfoSource
            },
            productNeedVisit: this.visitReportProducts
          };
        }

        this.ApiService.put(
          "visit/UpdateVisit?id=" + this.$route.params.visitId,
          data
        )
          .then(() => {
            if (this.photos != null) {
              this.uploadPhotos();
            }
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            //   let msg='تم إرسال تقرير بالزيارة'+ this.visit.task.name +'من قبل '+ this.user.fullName
            //   this.$socket.invoke('SendNewNotification',this.task.task.creatorId,this.user.id,msg,this.visit.task.idtask)

            this.$router.push({ name: "visits" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
          });
      }
    },
    getUsers() {
      this.ApiService.get("auth/listusers")
        .then(res => {
          var allUsers = res.data;
          allUsers.forEach(el => {
            this.users.push({ value: el.user.id, text: el.user.name });
          });
        })
        .catch(() => {});
    },
    async getCompanies() {
      this.companies = [{ item: "اختر شركة", value: "0" }];
      this.ApiService.get("company/GetAllCompanies")
        .then(res => {
          var allCompanies = res.data.responseData;
          allCompanies.forEach(el => {
            this.companies.push({ value: el.idcompany, item: el.name });
          });
        })
        .catch(() => {});
    },
    waitingReview() {
      this.ApiService.put("task/WaitingTasks/" + this.visit.idtask)
        .then(() => {})
        .catch(() => {});
    },
    async getProducts() {
      await this.ApiService.get("product/getproducts")
        .then(res => {
          var allProducts = res.data.responseData;
          allProducts.forEach(el => {
            this.products.push({ value: el.idproduct, item: el.name });
          });
        })
        .catch(() => {});
    },
    async getExternals() {
      this.externals = [{ item: "اختر جهة", value: "0" }];
      if (this.externalsType.value == 5) {
        await this.ApiService.get("External/getallproject?id=2")
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              if (this.externalsType.value == 5) {
                this.externals.push({ item: el.name, value: el.idproject });
              } else {
                this.externals.push({ item: el.name, value: el.idexternal });
              }
            });
            this.factories = res.data.responseData;
          })
          .catch(() => {});
      } else {
        await this.ApiService.get(
          "external/getallexternals?id=" + this.externalsType.value
        )
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              this.externals.push({ item: el.name, value: el.idexternal });
            });
            this.factories = res.data.responseData;
          })
          .catch(() => {});
      }
    }
  },
  created() {
    this.visit = this.$route.params.visitId;
    this.getTransporters();
    this.getCompanies();
    this.getProducts();
  }
};
</script>
<style scoped>
b {
  font-size: 14px;
}
</style>
